// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageJson from '../../package.json';
const { version } = packageJson;

export const environment = {
  production: false,
  sentryUrl: 'https://d3de412e147f47248a38ebdcd63de91c@o538195.ingest.sentry.io/6291968',

  // API_BASE_PATH: 'http://localhost:3000',                                   // Test Localhost nodejs port 3000 simple
  // API_BASE_PATH: "http://localhost:5001/eventhelper-prod/europe-west1/app", // TEST Local Firebase
  // API_BASE_PATH: "http://localhost:5001/eventhelper-dev/us-central1/app", // TEST firebase local dev
  // API_BASE_PATH: "https://eventhelper-dev.web.app/us-central1/app", // TEST firebase cloud dev
  // API_BASE_PATH: "https://eventhelper-59cf2.web.app",                          // PRODUCTION Firebase 


  // ----------------------------------------------------------- Firebose cloud functions Region us-central1
  // API_BASE_PATH: "https://us-central1-eventhelper-dev.cloudfunctions.net/app", // firebase cloud dev
  // API_BASE_PATH: "http://localhost:5001/eventhelper-dev/us-central1/app",      // TEST firebase local dev
  // API_BASE_PATH: "https://us-central1-eventhelper-test.cloudfunctions.net/app", // firebase cloud test/staging
  // API_BASE_PATH: "https://us-central1-eventhelper-prod.cloudfunctions.net/app", // firebase cloud prod

  // FIREBASE cloud functions Region europe-west1 ---------------------------------------------------------
  // API_BASE_PATH: "http://localhost:5001/eventhelper-dev/europe-west1/app",          // TEST firebase local dev
  // API_BASE_PATH: "http://localhost:5001/eventhelper-prod/europe-west1/app",          // TEST firebase local dev
  // -------------- LIVE PROD
  // API_BASE_PATH: "https://europe-west1-eventhelper-dev.cloudfunctions.net/app",  // firebase cloud dev
  // API_BASE_PATH: "https://europe-west1-eventhelper-test.cloudfunctions.net/app", // firebase cloud test
  API_BASE_PATH: "https://europe-west1-eventhelper-prod.cloudfunctions.net/app", // firebase cloud prod

  URL_EH: "https://eh.nxp.lk", // to redirect user to eventhelper

  APP_VERSION: version,

  FCM_KEY: 'BJuW4n_bUUSz4_nNSG3iICAMG7AUqCiWLcDH2CK2pr-Pc9Q_LAxMXH7-NxSSATM-NlzY_tC2pkekXMctiSpLzGI',

  firebase: {
    apiKey: "AIzaSyBSIcqF7zYmC3-k4JfatOBcTKv3PrGQc0Y",
    authDomain: "eventhelper-dev.firebaseapp.com",
    projectId: "eventhelper-dev",
    storageBucket: "eventhelper-dev.appspot.com",
    messagingSenderId: "910554186367",
    appId: "1:910554186367:web:f8b91a27c1272e4da5354a"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
